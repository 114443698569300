<template>
	<!-- 贷款申请信息 start -->
	<div>
		<a-descriptions v-if="loanInfo" bordered :title="$t('table.applyInfo.financialLabel.loanType')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.loanType')">
				{{ $t(`table.applyInfo.financialLabel.loanMap.${loanInfo.loanType}`) }}
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered size="default" v-if="loanInfo">
			<template slot="title">
				<span>{{ $t('table.applyInfo.tabsMenu.loanApply') }}</span>
				<a-button
					v-if="showEditBtn"
					:disabled="!isEditable"
					@click="setBeingRevised"
					type="primary"
					shape="circle"
					icon="form"
					style="margin-left: 20px"
				/>
				<a-button
					v-if="isShowDisbursementRecords"
					type="primary"
					style="margin-left: 10px"
					:disabled="!hasViewPermission()"
					@click="toLoanApplicationDisbursementRecords"
				>
					Disbursement Records
				</a-button>
			</template>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.loanFees')">
				<a-statistic :precision="2" :value="$Util.isEmpty(loanInfo.applyAmount) ? '' : loanInfo.applyAmount" />
			</a-descriptions-item>
			<a-descriptions-item
				:label="$t(`table.applyInfo.loanApplyLabel.${loanType[loanInfo.loanType - 5].aliasItem.includes(deItem) ? deItem + 'Alias' : deItem}`)"
				v-for="deItem in loanType[loanInfo.loanType - 5].descriptionsItem"
				:key="deItem"
			>
				<a-statistic :precision="2" :value="$Util.isEmpty(loanInfo[deItem]) ? '' : Math.round(loanInfo[deItem])" />
			</a-descriptions-item>
			<a-descriptions-item :label="reductions.name" v-for="reductions in loanInfo.reductions" :key="reductions.name">
				<a-statistic :precision="2" :value="$Util.isEmpty(reductions.amount) ? '' : reductions.amount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.payable')">
				<a-statistic :precision="2" :value="$Util.isEmpty(loanInfo.amountPayable) ? ' - ' : loanInfo.amountPayable" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.MonthlyInterest')">
				<span>{{ (loanInfo.interestRate * 100).toFixed(4) }}%</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.term')">
				<span>{{ loanInfo.installmentsNumbers }}</span>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.firstMonthRepayment')">
				<a-statistic :precision="2" :value="$Util.isEmpty(loanInfo.firstMonthRepay) ? 0 : Number(loanInfo.firstMonthRepay)" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.lastRepayment')">
				<a-statistic :precision="2" :value="$Util.isEmpty(loanInfo.otherMonthRepay) ? 0 : Number(loanInfo.otherMonthRepay)" />
			</a-descriptions-item>
			<a-descriptions-item v-if="isShowDisbursementRecords" label="Disbursed Amount">
				<a-statistic :precision="2" :value="disbursedAmount" />
			</a-descriptions-item>
		</a-descriptions>
	</div>
	<!-- 贷款申请信息 end -->
</template>
<script>
import { getDisbursedAmount } from '@/api/loan'

export default {
	props: ['loanInfo', 'detailData', 'showEditBtn', 'isEditable'],
	data() {
		return {
			loanType: [
				{
					name: 'Emergency Loan',
					value: 5,
					interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06, 0.075, 0.08, 0.1],
					installmentsOptions: Array.from({ length: 36 }, (_, i) => 1 + i),
					descriptionsItem: ['loanProcessingFee', 'mortgageRegistrationAmount', 'exciseDuty'],
					defaultsValue: {
						interestRate: 0.1,
						installmentsNumbers: 3,
						mortgageRegistrationAmount: 2500
					},
					defScale: {
						exciseDuty: '15%'
					},
					aliasItem: [],
					prefixType: 'EBC '
				},
				{
					name: 'Cash Pap',
					value: 6,
					interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06, 0.075, 0.08, 0.1],
					installmentsOptions: Array.from({ length: 36 }, (_, i) => 1 + i),
					descriptionsItem: ['loanProcessingFee', 'creditLifeInsuranceFee', 'mortgageRegistrationAmount', 'applicationFee', 'exciseDuty'],
					defaultsValue: {
						interestRate: 0.06,
						installmentsNumbers: 3,
						creditLifeInsuranceFee: 0,
						applicationFee: 1600,
						mortgageRegistrationAmount: 2500
					},
					defScale: {
						exciseDuty: '15%'
					},
					aliasItem: [],
					prefixType: 'CBC '
				}
			],
			isShowDisbursementRecords:
				['menus.emergencyLoanMenu.detail'].includes(this.$route.name) && ['/home/emergencyLoan/pendingList'].includes(this.$route.query.from),
			disbursedAmount: ''
		}
	},
	watch: {
		detailData: {
			handler: async function (newValue) {
				if (newValue?.id && this.isShowDisbursementRecords) {
					this.fetchDisbursedAmount()
				}
			},
			immediate: true
		}
	},
	methods: {
		async fetchDisbursedAmount() {
			const params = {
				loanId: this.detailData.id,
				loanType: this.detailData.loanType,
				disburseLoanType: this.detailData.emergencyFormInfo.loanInfo.loanType
			}
			this.disbursedAmount = await getDisbursedAmount(params)
		},
		setBeingRevised() {
			this.$emit('setDataChange', {
				key: 'beingRevised',
				value: true
			})
		},
		toLoanApplicationDisbursementRecords() {
			this.$router.push({
				path: 'loanApplicationDisbursementRecords',
				query: {
					loanId: this.detailData.id,
					loanType: this.detailData.loanType,
					disburseType: this.detailData.loanInfo.disburseType || 'dtb',
					disburseLoanType: this.detailData.emergencyFormInfo.loanInfo.loanType
				}
			})
		},
		hasViewPermission() {
			switch (this.$route.name) {
				case 'menus.loanDataMenu.pendingDetail':
					return this.$hasPermission('ROLE_PENGING_LOAN_VIEW')
				case 'menus.loanDataMenu.renewalDetail':
					return this.$hasPermission('ROLE_RENEWAL_PENDING_VIEW')
				case 'menus.emergencyLoanMenu.detail':
					return this.$hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_VIEW')
				default:
					return false
			}
		}
	}
}
</script>
